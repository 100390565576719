import { ActivePlayers, INVALID_MOVE, TurnOrder } from 'boardgame.io/core';
import { objectKeys } from './utils';
import {
  TRAIN_CARDS_DECK,
  MISSION_CARDS_DECK,
  DEMO_TRAIN_CARDS_DECK,
  MISSION_CARDS_DECK_EXPANSION_CLASSIC,
  ROUTES,
  MISSION_CARDS_DECK_MEGA,
  MISSION_CARDS_DECK_BIG_CITIES,
} from './getData';
import {
  BoardData,
  GameState,
  PlayerData,
  PlayerLongestRoad,
  GamePhase,
  MissionCard,
  PlayerMission,
  TrainCard,
  TrainColor,
  Route,
  City,
  PlayerInfo,
  GameMode,
  UserType,
} from './Types';

import { Ctx, Game, PlayerID } from 'boardgame.io';

export const NAMES_TO_COLORS = {
  purple: '#BC93FF' as const,
  yellow: '#FFFF00' as const,
  blue: '#93A4FF' as const,
  red: '#FF9F9F' as const,
  green: '#95FF93' as const,
};

export const NAMES_BY_COLORS = {
  purple: 'Lord Botie' as string,
  yellow: 'Robo Millie' as string,
  blue: 'Sir Bothat' as string,
  red: 'AI Scarlett' as string,
  green: 'Chip Topkin' as string,
};

export const COLORS_TO_NAMES = objectKeys(NAMES_TO_COLORS).reduce((acc, name) => {
  acc[NAMES_TO_COLORS[name]] = name;
  return acc;
}, {});

const ALL_COLORS = Object.values(NAMES_TO_COLORS);

// This has to be declared before its used
const selectMissionCards = {
  move: _innerSelectMissionCards,
  ignoreStaleStateID: true,
};

// This has to be declared before its used
const passPhase = {
  move: _InnerPassPhase,
  ignoreStaleStateID: true,
};

export const GAME_ID = 'ticket-to-ride';

export const TicketToRide: Game<GameState, Ctx> = {
  name: GAME_ID,
  validateSetupData(setupData, _numPlayers) {
    if (
      setupData !== undefined &&
      setupData.players !== undefined &&
      setupData.players.length > 0 &&
      setupData.players.every((player) => player.name !== undefined && player.avatarUrl !== undefined)
    ) {
      return;
    }
    console.error(`Bad setupData: ${JSON.stringify(setupData)}`);
    return 'Missing setupData, cannot create game.';
  },
  setup: (ctx: Ctx, setupData): GameState => {
    const players: Array<PlayerInfo> = setupData?.players;
    const colors = [...ALL_COLORS];

    if (players && players.length > 0) {
      const existingColors = players.map((player) => player.color);
      colors.filter((color) => existingColors.includes(color));
      players.forEach((player) => {
        player.isReady = false;
        player.color = colors.pop();
        player.colorName = COLORS_TO_NAMES[player.color];
        player.userType = player.userType;
        player.isBot = false;
        // || matchGameModeToPlayerName(player.name); //****** for testing only - delete when going to production*****//
      });
    }
    const gameEnded = false;
    const turnEnded = null;
    const phaseEnded = null;
    const rulesData = {
      numberOfTrainCardsEachPlayerStartsWith: 4,
      numberOfTrainCarsEachPlayerStartsWith: 45,
      numberOfTrainCardsOpenOnTheTable: 5,
      minAmountOfMissionCardsToSelectDuringGame: 1,
      minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
      amountOfMissionCardsToDealDuringGame: 3,
      amountOfMissionCardsToDealDuringMissionSelectPhase: 3,
      rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
    };
    const boardData: BoardData = {
      trainCardsDeck: [],
      usedTrainCards: [],
      usedMissionCards: [],
      missionsCardsDeck: [],
      trainCardsTable: [],
      lastTurnCounter: 0,
      winnerID: -1,
      routes: [...ROUTES],
      cardsDiscardedByJokersLastTurn: [],
    };
    const gameMode: GameMode = GameMode.UsaClassic;
    const seniorUserType: UserType = UserType.FirstTimeUser;
    const turnOrder = players.map((_, i) => i.toString());
    console.log(turnOrder);

    return {
      gameMode,
      rulesData,
      boardData,
      turnEnded,
      phaseEnded,
      gameEnded,
      playersData: [],
      colors,
      players,
      turnOrder,
      tutorialStep: 0,
      seniorUserType,
    };
  },

  moves: {
    selectMissionCards,
    pickTrainCardFromTable,
    pickTrainCardFromDeck,
    discoverMissionCards,
    establishRoute,
    passTurn,
    passPhase,
    replacePlayerWithBot,
  },
  phases: {
    [GamePhase.Intro]: {
      start: true,
      onBegin: (G, ctx) => {
        console.log('================ Starting Intro Phase ================');
      },
      turn: {
        activePlayers: ActivePlayers.ALL,
      },
      moves: {
        endIntro: (G, ctx) => {
          ctx.events.endPhase();
        },
      },
      onEnd: (G, ctx) => {
        console.log('================ Ending Intro Phase ================');
      },
      next: GamePhase.Setup,
    },

    [GamePhase.Setup]: {
      onBegin: (G, ctx) => {
        console.log('================ Starting setup ================');
        //Fill bots to 4 players
        const botsToAdd = 4 - G.players.length;
        for (let i = 0; i < botsToAdd; i++) {
          addBot(G);
        }

        //Find the senior user type and set game mode accordingly
        matchGameModeToPlayerType(G);
        console.log('user type is' + G.seniorUserType);
        if (G.seniorUserType === UserType.FirstTimeUser) {
          startGame(G, ctx);
        }
      },
      onEnd: (G, ctx) => {
        console.log('================ Setup Phase ended ================');
        console.log('================ Number of players: ' + G.players.length + ' ================');
      },
      turn: {
        activePlayers: ActivePlayers.ALL,
      },
      moves: {
        incBots: (G) => {
          addBot(G);
        },
        removeBot: (G, _, playerId: PlayerID) => {
          G.players = G.players.filter((playerInfo, i) => i.toString() !== playerId);
        },
        selectGameMode: (G, ctx, gameMode: GameMode) => {
          G.gameMode = gameMode;
        },
        setColor,
        startGame,
        replacePlayerWithBot,
      },
      next: GamePhase.MissionSelection,
    },

    [GamePhase.Tutorial]: {
      onBegin: (G, ctx) => {
        console.log('================ Starting tutorial ================');
      },
      turn: {
        activePlayers: ActivePlayers.ALL,
      },
      moves: {
        endTutorial: (G, ctx) => {
          G.tutorialStep = 0;
          ctx.events.endPhase();
        },
        incTutorialStep: (G, ctx) => {
          G.tutorialStep += 1;
          return undefined;
        },
        decreaseTutorialStep: (G, ctx) => {
          G.tutorialStep -= 1;
          return undefined;
        },
      },
      next: GamePhase.Setup,
    },

    [GamePhase.MissionSelection]: {
      next: GamePhase.GameTurns,
      moves: { selectMissionCards, passPhase, replacePlayerWithBot },
      turn: {
        order: TurnOrder.RESET,
        activePlayers: ActivePlayers.ALL,
        onMove: (G, ctx) => {
          if (allPlayersSelectedMissionCards(G, ctx)) {
            G.phaseEnded = { phase: ctx.phase };
          }
        },
      },
      onBegin: (G, ctx) => {
        console.log('================ Mission Selection Phase started ================');
        // Shuffles player turn order before game turns
        if (G.gameMode !== 'demo') {
          G.turnOrder = ctx.random.Shuffle(G.players.map((_, i) => i.toString()));
        } else {
          G.turnOrder = G.players.map((_, i) => i.toString());
        }

        dealMissionCardsForeachOfThePlayers(G, ctx);
      },
      onEnd(G, ctx) {
        console.log('================ Ending mission selection phase ================');
      },
    },

    [GamePhase.GameTurns]: {
      onBegin: (G, ctx) => {
        console.log('================ GameTurns Phase started ================');
        console.log('turn order: ' + G.turnOrder);
      },
      next: GamePhase.Scoring,
      moves: {
        pickTrainCardFromTable,
        pickTrainCardFromDeck,
        discoverMissionCards,
        selectMissionCards,
        establishRoute,
        passTurn,
        replacePlayerWithBot,
      },
      turn: {
        order: TurnOrder.CUSTOM_FROM('turnOrder'),
        onBegin: (G, ctx) => {
          console.log('================ Player ' + ctx.currentPlayer + ' turns started ================');
          setTurnStateData(G, ctx);
        },
        onEnd(G, ctx) {
          if (G.boardData.trainCardsDeck.length === 0 && G.boardData.usedTrainCards.length > 0) {
            reshuffleTrainDeck(G, ctx);
          }
          if (G.boardData.trainCardsTable.includes(null)) {
            fillMissingCardsOnTable(G, ctx);
          }
          if (G.boardData.lastTurnCounter > 0) {
            G.boardData.lastTurnCounter++;
          }
          console.log('================ Player ' + ctx.currentPlayer + ' turn ended ================');
        },
      },
      endIf: (G, ctx) => {
        if (G.boardData.lastTurnCounter > G.players.length + 1) {
          return true;
        }
        return false;
      },
    },
    [GamePhase.Scoring]: {
      turn: {
        order: TurnOrder.RESET,
        activePlayers: ActivePlayers.ALL,
      },
      next: GamePhase.Winner,
      moves: { passPhase },
      onBegin: (G, ctx) => {
        console.log('================ mission cards calculation ================');
        calculateMissionCardsPoints(G, ctx);
        console.log('================ longest road calculation ================');
        calculateLongestRoad(G, ctx);
        console.log('================ GlobeTrotter calculation ================');
        calculateGlobeTrotter(G, ctx);
        console.log('================ Ending scroing phase ================');
        G.phaseEnded = { phase: ctx.phase };
      },
      onEnd: (G, ctx) => {
        console.log('================ Scoring ended ================');
      },
    },

    [GamePhase.Winner]: {
      turn: {
        order: TurnOrder.RESET,
        activePlayers: ActivePlayers.ALL,
      },
      next: GamePhase.PlayAgain,
      moves: { passPhase },
      onBegin: (G, ctx) => {
        console.log('================ winner calculation ================');
        calculateWinner(G, ctx);
        console.log('================ Ending winner phase ================');
        G.phaseEnded = { phase: ctx.phase };
      },
      onEnd: (G, ctx) => {
        console.log('================ Game ended ================');
      },
    },

    [GamePhase.PlayAgain]: {
      onBegin: (G, ctx) => {
        console.log('================ playAgain started ================');
      },
      onEnd: (G, ctx) => {
        console.log('================ playAgain ended ================');
      },
    },
  },

  endIf: (G: GameState, ctx) => {
    return false;
  },
};

//-------Setup functions---------

function matchGameModeToPlayerType(G: GameState) {
  let seniorUserType = UserType.FirstTimeUser;
  let hasPayingUser = false;

  for (let i = 0; i < G.players.length; i++) {
    if (G.players[i].userType === UserType.PayingUser) {
      G.gameMode = GameMode.UsaClassic;
      seniorUserType = UserType.PayingUser;
      hasPayingUser = true;
      break;
    } else if (G.players[i].userType === UserType.SecondTimeUser) {
      if (!hasPayingUser) {
        G.gameMode = GameMode.Demo;
        seniorUserType = UserType.SecondTimeUser;
      }
    } else {
      if (!hasPayingUser && seniorUserType === UserType.FirstTimeUser) {
        G.gameMode = GameMode.Demo;
      }
    }
  }
  G.seniorUserType = seniorUserType;
}

function matchGameModeToPlayerName(playerName: string) {
  //****** for testing purpuses - delete this function when going to production*****//
  switch (playerName) {
    case 'PU': {
      return UserType.PayingUser;
    }
    case 'STU': {
      return UserType.SecondTimeUser;
    }
    default: {
      return UserType.FirstTimeUser;
    }
  }
}

function shuffleMissionDeck(G: GameState, ctx: Ctx) {
  G.boardData.missionsCardsDeck = ctx.random.Shuffle(G.boardData.missionsCardsDeck);
}

function shuffleTrainDeck(G: GameState, ctx: Ctx) {
  G.boardData.trainCardsDeck = ctx.random.Shuffle(G.boardData.trainCardsDeck);
}

function printG(G: GameState) {
  console.log(JSON.stringify(G));
}

function openTrainCardsToTable(G: GameState, ctx: Ctx) {
  G.boardData.trainCardsTable = G.boardData.trainCardsDeck.splice(0, G.rulesData.numberOfTrainCardsOpenOnTheTable);
  while (checkIfThereAreMoreThanThreeMultiColorTrainCardsOnTheTable(G)) {
    refreshTrainCardsOnTable(G, ctx);
  }
}

function SetupPlayers(G: GameState, ctx: Ctx) {
  console.log('setting up players');
  for (let i = 0; i < G.players.length; i++) {
    const playerMissions: Array<PlayerMission> = [];
    const playerData: PlayerData = {
      trainCards: getTrainCardsForSetup(G, ctx, G.rulesData.numberOfTrainCardsEachPlayerStartsWith),
      trainCars: G.rulesData.numberOfTrainCarsEachPlayerStartsWith,
      missionCards: playerMissions,
      longestRoad: null,
      isGlobeTrotter: false,
      score: 0,
      turnState: {
        missionCardsDiscovered: [],
        TrainCardsPicked: [],
        usedTrainCards: [],
        availableRoutesToBuildByCardColor: {},
      },
    };

    G.playersData.push(playerData);
    console.log('player ' + i + ' setup: ');
    console.log(G.playersData[i]);
  }
}

function SetupRules(G: GameState, ctx: Ctx) {
  console.log('setting up game rules');
  switch (G.gameMode) {
    case GameMode.UsaClassic:
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
        amountOfMissionCardsToDealDuringGame: 3,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 3,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
      break;
    case GameMode.Demo:
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
        amountOfMissionCardsToDealDuringGame: 3,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 3,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...DEMO_TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
      break;
    case GameMode.ExpansionClassic:
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
        amountOfMissionCardsToDealDuringGame: 3,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 3,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK_EXPANSION_CLASSIC]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
      break;
    case GameMode.Mega:
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 3,
        amountOfMissionCardsToDealDuringGame: 4,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 5,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK_MEGA]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
      break;
    case GameMode.BigCities:
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
        amountOfMissionCardsToDealDuringGame: 4,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 4,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK_BIG_CITIES]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
      break;

    default:
      //classic rules
      G.rulesData = {
        numberOfTrainCardsEachPlayerStartsWith: 4,
        numberOfTrainCarsEachPlayerStartsWith: 45,
        numberOfTrainCardsOpenOnTheTable: 5,
        minAmountOfMissionCardsToSelectDuringGame: 1,
        minAmountOfMissionCardsToSelectDuringMissionSelectPhase: 2,
        amountOfMissionCardsToDealDuringGame: 3,
        amountOfMissionCardsToDealDuringMissionSelectPhase: 3,
        rewardForEstablishingRouteByRouteLength: { 1: 1, 2: 2, 3: 4, 4: 7, 5: 10, 6: 15 },
      };
      G.boardData.missionsCardsDeck = [...MISSION_CARDS_DECK]; // update once added classic and mega missions
      G.boardData.trainCardsDeck = [...TRAIN_CARDS_DECK]; // update once added classic and mega train deck
      G.boardData.routes = [...ROUTES]; // update once added classic and mega routes
  }
  console.log('Game mode ' + G.gameMode);
}

function getTrainCardsForSetup(G: GameState, ctx: Ctx, nubmerOfTrainCardsPerPlayer: number): Array<TrainCard> {
  const trainCards: Array<TrainCard> = [];
  for (let i = 0; i < nubmerOfTrainCardsPerPlayer; i++) {
    trainCards.push(G.boardData.trainCardsDeck.pop());
  }
  return trainCards;
}

function addBot(G: GameState) {
  const color = ALL_COLORS.find((color) => !G.players.some((player) => player.color === color));
  G.players.push({
    name: NAMES_BY_COLORS[COLORS_TO_NAMES[color]],
    avatarUrl: 'https://i.imgur.com/7DlDdYk.png',
    isBot: true,
    color: color,
    colorName: COLORS_TO_NAMES[color],
    isReady: true,
    userType: UserType.Bot,
  });
}

function setColor(G: GameState, ctx: Ctx, color: string): void | typeof INVALID_MOVE {
  const otherPlayerWithSameColor = G.players.find((player) => player.color === color);
  if (otherPlayerWithSameColor === undefined || otherPlayerWithSameColor.isBot) {
    if (otherPlayerWithSameColor?.isBot === true) {
      otherPlayerWithSameColor.color = G.players[+ctx.playerID].color;
      otherPlayerWithSameColor.colorName = G.players[+ctx.playerID].colorName;
      otherPlayerWithSameColor.name = NAMES_BY_COLORS[COLORS_TO_NAMES[otherPlayerWithSameColor.color]];
    }
    G.players[+ctx.playerID].color = color;
    G.players[+ctx.playerID].colorName = COLORS_TO_NAMES[color];
    return;
  } else {
    return INVALID_MOVE;
  }
}

function startGame(G: GameState, ctx: Ctx): void {
  SetupRules(G, ctx);
  if (G.gameMode !== 'demo') {
    shuffleMissionDeck(G, ctx);
    shuffleTrainDeck(G, ctx);
    printG(G);
  }
  SetupPlayers(G, ctx);
  console.log('players setup done');
  openTrainCardsToTable(G, ctx);
  ctx.events.setPhase(GamePhase.MissionSelection);
}

//-------Mission Selection functions---------

function allPlayersSelectedMissionCards(G: GameState, ctx: Ctx): boolean {
  let numOfPlayersThatSelectedCards = 0;
  G.playersData.forEach((player) => {
    if (player.missionCards.length > 0) {
      numOfPlayersThatSelectedCards++;
    }
  });
  if (numOfPlayersThatSelectedCards === G.playersData.length) {
    return true;
  } else {
    return false;
  }
}

//-------Game Turns functions---------

function setTurnStateData(G: GameState, ctx: Ctx) {
  const player = G.playersData[parseInt(ctx.currentPlayer)];
  player.turnState.TrainCardsPicked = [];
  player.turnState.usedTrainCards = [];
  player.turnState.missionCardsDiscovered = [];
  player.turnState.availableRoutesToBuildByCardColor = getAvailableRoutesToBuildByCardColor(G, ctx, player);
}

//-------Moves---------

function establishRoute(
  G: GameState,
  ctx: Ctx,
  routeID: number,
  selectedTrainColor: TrainColor
): void | typeof INVALID_MOVE {
  if (G.turnEnded !== null) {
    console.log(`Turn ended allready when trying to make a move`);
    return INVALID_MOVE;
  }
  console.log('trying to establish route id ' + routeID + ' with ' + selectedTrainColor + ' trains');
  const playerData: PlayerData = G.playersData[parseInt(ctx.currentPlayer)];

  if (playerData.turnState.TrainCardsPicked.length > 0) {
    return INVALID_MOVE;
  }

  if (playerData.turnState.missionCardsDiscovered.length > 0) {
    return INVALID_MOVE;
  }

  if (
    selectedTrainColor !== TrainColor.Multicolored &&
    selectedTrainColor !== G.boardData.routes.find((route) => route.routeID === routeID).routeColor &&
    G.boardData.routes.find((route) => route.routeID === routeID).routeColor !== TrainColor.Any
  ) {
    return INVALID_MOVE;
  }

  if (!checkIfRouteIsAvailable(G, routeID)) {
    return INVALID_MOVE;
  }

  if (!checkIfPlayerHasEnoughTrainCars(G, playerData, routeID)) {
    return INVALID_MOVE;
  }

  if (!checkIfPlayerHasEnoughTrainCards(G, playerData, routeID, selectedTrainColor)) {
    return INVALID_MOVE;
  }

  removeUsedCarsFromPlayerHand(G, playerData, routeID);
  discardUsedTrainCards(G, playerData, routeID, selectedTrainColor);
  updateRouteOwnersByRouteID(G, ctx, routeID);
  updatePlayerScoreForEstablishingRoute(G, playerData, routeID);
  updatePlayerMissionsDetails(G, ctx);
  excludeParallelRoutesIfNeeded(G, ctx, routeID);

  if (checkIfCurrentPlayerHasTwoCarsOrLess(G, ctx) && G.boardData.lastTurnCounter === 0) {
    console.log('Player ' + ctx.currentPlayer + ' got less then 3 train cars, this is the last round of the game!');
    G.boardData.lastTurnCounter++;
  }

  G.turnEnded = { playerID: ctx.currentPlayer, turn: ctx.turn };
}

function passTurn(G: GameState, ctx: Ctx): void {
  if (G.turnEnded && G.turnEnded.playerID === ctx.currentPlayer && G.turnEnded.turn === ctx.turn) {
    G.turnEnded = null;
    ctx.events.endTurn();
  }
}

function _InnerPassPhase(G: GameState, ctx: Ctx): void {
  if (G.phaseEnded && G.phaseEnded.phase === ctx.phase) {
    G.phaseEnded = null;
    ctx.events.endPhase();
  }
}

function discoverMissionCards(G: GameState, ctx: Ctx): void | typeof INVALID_MOVE {
  if (G.turnEnded !== null) {
    console.log(`Turn ended allready when trying to make a move`);
    return INVALID_MOVE;
  }
  console.log('discovering mission cards');
  const playerData: PlayerData = G.playersData[parseInt(ctx.currentPlayer)];

  if (playerData.turnState.TrainCardsPicked.length > 0) {
    return INVALID_MOVE;
  }

  if (playerData.turnState.missionCardsDiscovered.length > 0) {
    return INVALID_MOVE;
  }

  if (G.boardData.missionsCardsDeck.length < 1) {
    return INVALID_MOVE;
  }

  G.playersData[parseInt(ctx.currentPlayer)].turnState.missionCardsDiscovered = getMissionCards(G, ctx);

  if (
    G.gameMode != GameMode.Demo &&
    G.gameMode != GameMode.UsaClassic &&
    G.boardData.missionsCardsDeck.length < G.rulesData.amountOfMissionCardsToDealDuringGame
  ) {
    reshuffleMissionDeck(G, ctx);
  }
}

function _innerSelectMissionCards(G: GameState, ctx: Ctx, cardsPickedIndex: Array<number>): void | typeof INVALID_MOVE {
  console.log('selecting mission cards ' + cardsPickedIndex.toString());
  const playerData: PlayerData = G.playersData[parseInt(ctx.playerID)];
  let minNumOfCardsToPick: number = G.rulesData.minAmountOfMissionCardsToSelectDuringGame;
  if (ctx.phase === GamePhase.MissionSelection) {
    minNumOfCardsToPick = G.rulesData.minAmountOfMissionCardsToSelectDuringMissionSelectPhase;
  }

  if (minNumOfCardsToPick > cardsPickedIndex.length) {
    return INVALID_MOVE;
  }
  if (playerData.turnState.TrainCardsPicked.length > 0) {
    return INVALID_MOVE;
  }

  for (let i = 0; i < playerData.turnState.missionCardsDiscovered.length; i++) {
    if (cardsPickedIndex.includes(i)) {
      const playerMission: PlayerMission = {
        missionCard: playerData.turnState.missionCardsDiscovered[i],
        status: false,
        path: [],
      };
      G.playersData[parseInt(ctx.playerID)].missionCards.push(playerMission);
    } else {
      G.boardData.usedMissionCards.push(playerData.turnState.missionCardsDiscovered[i]);
    }
  }

  if (ctx.phase !== GamePhase.MissionSelection) {
    updatePlayerMissionsDetails(G, ctx);
    G.turnEnded = { playerID: ctx.currentPlayer, turn: ctx.turn };
  }
}

function pickTrainCardFromDeck(G: GameState, ctx: Ctx): void | typeof INVALID_MOVE {
  if (G.turnEnded !== null) {
    console.log(`Turn ended allready when trying to make a move`);
    return INVALID_MOVE;
  }
  console.log('picking a train card from deck');
  const playerData = G.playersData[parseInt(ctx.currentPlayer)];

  if (G.boardData.trainCardsDeck.length === 0 && G.boardData.usedTrainCards.length === 0) {
    console.log(`INVALID MOVE first if in pickTrainCardFromDeck`);
    return INVALID_MOVE;
  }

  if (playerData.turnState.missionCardsDiscovered.length > 0) {
    console.log(
      `INVALID MOVE because: playerData.turnState.missionCardsDiscovered.length: ${playerData.turnState.missionCardsDiscovered.length}`
    );
    return INVALID_MOVE;
  }

  if (G.boardData.trainCardsDeck.length <= 2 && G.boardData.usedTrainCards.length > 0) {
    reshuffleTrainDeck(G, ctx);
  }

  const selectedTrainCard: TrainCard = G.boardData.trainCardsDeck.pop();
  playerData.turnState.TrainCardsPicked.push(selectedTrainCard);
  playerData.trainCards.push(selectedTrainCard);

  const cardsLeftToPick: number = G.boardData.trainCardsTable.filter((card) => card !== null).length;

  if (playerData.turnState.TrainCardsPicked.length > 1 || cardsLeftToPick === 0) {
    console.log('Setting turnEnded because we took enough cards');
    G.turnEnded = { playerID: ctx.currentPlayer, turn: ctx.turn };
  }
}

function reshuffleTrainDeck(G: GameState, ctx: Ctx) {
  console.log('Suffleling Trains deck');
  G.boardData.trainCardsDeck = [...G.boardData.trainCardsDeck, ...ctx.random.Shuffle(G.boardData.usedTrainCards)];
  console.log('train cards deck length: ' + G.boardData.trainCardsDeck.length);
  G.boardData.usedTrainCards = [];
}

function reshuffleMissionDeck(G: GameState, ctx: Ctx) {
  console.log('Suffleling mission deck');
  G.boardData.missionsCardsDeck = [
    ...G.boardData.missionsCardsDeck,
    ...ctx.random.Shuffle(G.boardData.usedMissionCards),
  ];
  console.log('mission cards deck length: ' + G.boardData.missionsCardsDeck.length);
  G.boardData.usedMissionCards = [];
}

function pickTrainCardFromTable(G: GameState, ctx: Ctx, cardPickedIndex: number): void | typeof INVALID_MOVE {
  if (G.turnEnded !== null) {
    console.log(`Turn ended allready when trying to make a move`);
    return INVALID_MOVE;
  }
  console.log('clear discarded train cards due to 3 jokers refresh');
  G.boardData.cardsDiscardedByJokersLastTurn = [];

  console.log('Picking a ' + G.boardData.trainCardsTable[cardPickedIndex].Color + ' card from table');

  const playerData = G.playersData[parseInt(ctx.currentPlayer)];

  if (cardPickedIndex < 0 || cardPickedIndex > G.rulesData.numberOfTrainCardsOpenOnTheTable) {
    console.log('INVALID MOVE in pickTrainCardFromTable first if');
    return INVALID_MOVE;
  }

  if (playerData.turnState.missionCardsDiscovered.length > 0) {
    console.log('INVALID MOVE in pickTrainCardFromTable second if');
    return INVALID_MOVE;
  }

  if (
    playerData.turnState.TrainCardsPicked.length > 0 &&
    G.boardData.trainCardsTable[cardPickedIndex].Color === TrainColor.Multicolored
  ) {
    return INVALID_MOVE;
  }

  if (G.boardData.trainCardsDeck.length <= 2 && G.boardData.usedTrainCards.length > 0) {
    reshuffleTrainDeck(G, ctx);
  }

  const selectedTrainCard: TrainCard = G.boardData.trainCardsTable.splice(cardPickedIndex, 1).pop();

  if (G.boardData.trainCardsDeck.length > 0) {
    openNewTrainCardToTable(G, ctx, cardPickedIndex);
  } else {
    addNullCardToTable(G, cardPickedIndex);
  }
  playerData.turnState.TrainCardsPicked.push(selectedTrainCard);
  playerData.trainCards.push(selectedTrainCard);

  const cardsLeftToPick: number = G.boardData.trainCardsTable.filter((card) => card !== null).length;

  if (
    selectedTrainCard.Color === TrainColor.Multicolored ||
    playerData.turnState.TrainCardsPicked.length > 1 ||
    cardsLeftToPick === 0
  ) {
    console.log('Setting turnEnded because we took enough cards');
    G.turnEnded = { playerID: ctx.currentPlayer, turn: ctx.turn };
  }
}

function replacePlayerWithBot(G: GameState, ctx: Ctx): void | typeof INVALID_MOVE {
  const playerInfo: PlayerInfo = G.players[parseInt(ctx.playerID)];
  playerInfo.isBot = true;
  playerInfo.userType = UserType.Bot;
  console.log('replacePlayerWithBot');
}

//-------General functions---------

function openNewTrainCardToTable(G: GameState, ctx: Ctx, cardIndex: number) {
  console.log('opening a new train card to table, position ' + cardIndex);
  G.boardData.trainCardsTable.splice(cardIndex, 0, G.boardData.trainCardsDeck.pop());
  while (
    checkIfThereAreMoreThanThreeMultiColorTrainCardsOnTheTable(G) &&
    checkIfthereAreEnoughRegularTrainCardsToRefreshTable(G)
  ) {
    rememberDiscardedCards(G);
    refreshTrainCardsOnTable(G, ctx);
  }
}

function replaceNullCardOnTable(G: GameState, ctx: Ctx, cardIndex: number) {
  console.log('replacing null card on table position ' + cardIndex);
  G.boardData.trainCardsTable[cardIndex] = G.boardData.trainCardsDeck.pop();
  while (
    checkIfThereAreMoreThanThreeMultiColorTrainCardsOnTheTable(G) &&
    checkIfthereAreEnoughRegularTrainCardsToRefreshTable(G)
  ) {
    refreshTrainCardsOnTable(G, ctx);
  }
}

function addNullCardToTable(G: GameState, cardIndex: number) {
  console.log('Addibng NULL to table cards position ' + cardIndex);
  G.boardData.trainCardsTable.splice(cardIndex, 0, null);
}

function fillMissingCardsOnTable(G: GameState, ctx: Ctx) {
  console.log('Searching for null cards on table ');
  for (let i = 0; i < G.boardData.trainCardsTable.length; i++) {
    if (G.boardData.trainCardsTable[i] === null && G.boardData.trainCardsDeck.length > 0) {
      console.log('adding new card to table');
      replaceNullCardOnTable(G, ctx, i);
    }
  }
}

function dealMissionCardsForeachOfThePlayers(G: GameState, ctx: Ctx) {
  console.log('Dealing mission cards to each of the players');
  G.playersData.forEach((player) => {
    player.turnState.missionCardsDiscovered = getMissionCards(G, ctx);
  });
}

function getMissionCards(G: GameState, ctx: Ctx): Array<MissionCard> {
  console.log('Drawing mission cards from the deck');
  const discoverCards: Array<MissionCard> = [];
  let discoverSize: number;
  if (ctx.phase === GamePhase.MissionSelection) {
    discoverSize = G.rulesData.amountOfMissionCardsToDealDuringMissionSelectPhase;
  } else {
    discoverSize = G.rulesData.amountOfMissionCardsToDealDuringGame;
  }
  for (let i = 0; i < discoverSize; i++) {
    if (G.boardData.missionsCardsDeck.length > 0) discoverCards.push(G.boardData.missionsCardsDeck.pop());
  }
  return discoverCards;
}

function checkIfRouteIsAvailable(G: GameState, routeID: number): boolean {
  console.log('Checking if route is available');
  if (G.boardData.routes.find((route) => route.routeID === routeID).ownerID === undefined) {
    return true;
  }
  return false;
}

export function checkIfPlayerHasEnoughTrainCars(G: GameState, playerData: PlayerData, routeID: number): boolean {
  console.log("Removing used train cars from player's hand");

  if (G.boardData.routes.find((route) => route.routeID === routeID).numberOfTrains <= playerData.trainCars) {
    return true;
  }
  return false;
}

export function checkIfPlayerHasEnoughTrainCards(
  G: GameState,
  playerData: PlayerData,
  routeID: number,
  selectedTrainColor: TrainColor
): boolean {
  console.log('Checking if player has enough train cards to establish the route');
  let numberOfTrainCardsFromSelectedColor = 0;
  playerData.trainCards.forEach((card) => {
    if (card.Color === selectedTrainColor || card.Color === TrainColor.Multicolored) {
      numberOfTrainCardsFromSelectedColor++;
    }
  });
  if (
    G.boardData.routes.find((route) => route.routeID === routeID).numberOfTrains <= numberOfTrainCardsFromSelectedColor
  ) {
    return true;
  }
  return false;
}

function removeUsedCarsFromPlayerHand(G: GameState, playerData: PlayerData, routeID: number) {
  console.log("Removing used train cars from player's hand");
  playerData.trainCars -= G.boardData.routes.find((route) => route.routeID === routeID).numberOfTrains;
}

function discardUsedTrainCards(G: GameState, playerData: PlayerData, routeID: number, selectedTrainColor: TrainColor) {
  console.log('Discarding used train cards');
  let numberOfTrainCardsToUse = G.boardData.routes.find((route) => route.routeID === routeID).numberOfTrains;
  const cardIndecesToUse: Array<number> = [];

  for (let i = 0; i < playerData.trainCards.length; i++) {
    if (playerData.trainCards[i].Color === selectedTrainColor && numberOfTrainCardsToUse > 0) {
      cardIndecesToUse.push(i);
      numberOfTrainCardsToUse--;
    }
  }

  for (let i = 0; i < playerData.trainCards.length; i++) {
    if (playerData.trainCards[i].Color === TrainColor.Multicolored && numberOfTrainCardsToUse > 0) {
      cardIndecesToUse.push(i);
      numberOfTrainCardsToUse--;
    }
  }

  removeUsedCards(G, playerData, cardIndecesToUse);
}

function removeUsedCards(G: GameState, playerData: PlayerData, cardIndices: Array<number>) {
  // First, sort the indices in descending order.
  cardIndices.sort((a, b) => b - a);

  // Extract the cards to be removed and push them to the usedTrainCards array.
  const cardsToRemove = cardIndices.map((index) => playerData.trainCards[index]);
  G.boardData.usedTrainCards.push(...cardsToRemove);
  playerData.turnState.usedTrainCards.push(...cardsToRemove);

  // Create a new array without the removed cards.
  playerData.trainCards = playerData.trainCards.filter((_, index) => !cardIndices.includes(index));
}

function updateRouteOwnersByRouteID(G: GameState, ctx: Ctx, routeID: number) {
  console.log('Establishing route on the board');
  G.boardData.routes.find((route) => route.routeID === routeID).ownerID = parseInt(ctx.currentPlayer);
}

function updatePlayerScoreForEstablishingRoute(G: GameState, playerData: PlayerData, routeID: number) {
  console.log("Updating player's score based on the length of the new route");
  playerData.score +=
    G.rulesData.rewardForEstablishingRouteByRouteLength[
      G.boardData.routes.find((route) => route.routeID === routeID).numberOfTrains
    ];
}

function updatePlayerMissionsDetails(G: GameState, ctx: Ctx) {
  console.log('Updating player mission status');
  const playerData: PlayerData = G.playersData[parseInt(ctx.currentPlayer)];
  playerData.missionCards.forEach((mission) => {
    if (mission.status !== true) {
      if (areCitiesConnected(G, parseInt(ctx.currentPlayer), mission.missionCard.From, mission.missionCard.To)) {
        mission.status = true;
        const playerRoutes = G.boardData.routes.filter((route) => route.ownerID === parseInt(ctx.currentPlayer));
        mission.path = findShortestPath(playerRoutes, mission.missionCard.From, mission.missionCard.To);
      }
    }
  });
}

function findShortestPath(routes: Array<Route>, startCity: City, endCity: City): Array<Route> {
  const queue = []; // This will store the path
  const visited = new Set(); // To ensure we don't visit a city more than once

  // Starting with the initial city
  queue.push([startCity]);

  while (queue.length > 0) {
    const path = queue.shift(); // Get the first path from the queue
    const lastCity = path[path.length - 1];

    // Check if the last city of this path is the destination
    if (lastCity === endCity) {
      const shortestRoute: Array<Route> = [];
      for (let i = 0; i < path.length - 1; i++) {
        for (const route of routes) {
          if (
            (route.cityA === path[i] && route.cityB === path[i + 1]) ||
            (route.cityB === path[i] && route.cityA === path[i + 1])
          ) {
            shortestRoute.push(route);
          }
        }
      }
      return shortestRoute;
    }

    // If not, extend the path to the neighboring cities and add to the queue
    for (const route of routes) {
      if (route.cityA === lastCity && !visited.has(route.cityB)) {
        visited.add(route.cityB);
        const newPath = [...path, route.cityB];
        queue.push(newPath);
      } else if (route.cityB === lastCity && !visited.has(route.cityA)) {
        visited.add(route.cityA);
        const newPath = [...path, route.cityA];
        queue.push(newPath);
      }
    }
  }

  return null; // Return null if no path exists
}

function checkIfCurrentPlayerHasTwoCarsOrLess(G: GameState, ctx: Ctx): boolean {
  console.log('Checking if player has 2 train cars or less');
  const playerData: PlayerData = G.playersData[parseInt(ctx.currentPlayer)];
  if (playerData.trainCars <= 2) {
    return true;
  } else {
    return false;
  }
}

function calculateMissionCardsPoints(G: GameState, ctx: Ctx) {
  const playersData = G.playersData;
  for (let i = 0; i < playersData.length; i++) {
    console.log('Calculating Mission card for player ' + i);
    playersData[i].missionCards.forEach((mission) => {
      if (mission.status === true) {
        console.log(
          'Mission card from ' +
            mission.missionCard.From +
            'to' +
            mission.missionCard.To +
            ' completed. + ' +
            mission.missionCard.Value +
            ' points!'
        );
        playersData[i].score += mission.missionCard.Value;
      } else {
        playersData[i].score -= mission.missionCard.Value;
        console.log(
          'Mission card from ' +
            mission.missionCard.From +
            'to' +
            mission.missionCard.To +
            ' denied. - ' +
            mission.missionCard.Value +
            ' points!'
        );
      }
    });
  }
}

function calculateLongestRoad(G: GameState, ctx: Ctx) {
  const playersData = G.playersData;
  let longestPathLength = 0;
  for (let i = 0; i < playersData.length; i++) {
    const playerRoutes: Array<Route> = G.boardData.routes.filter((route) => route.ownerID === i);
    const longestPathData = findLongestRoad(playerRoutes);
    const playerLongestRoad: PlayerLongestRoad = {
      length: longestPathData.longestPath,
      longestPath: longestPathData.longestPathEdges,
      isLongest: false,
    };
    G.playersData[i].longestRoad = playerLongestRoad;
    console.log('Player ' + i + ' longest path is: ' + G.playersData[i].longestRoad.length);
    if (G.playersData[i].longestRoad.length > longestPathLength) {
      longestPathLength = G.playersData[i].longestRoad.length;
    }
  }
  for (let i = 0; i < playersData.length; i++) {
    if (G.playersData[i].longestRoad.length >= longestPathLength) {
      G.playersData[i].longestRoad.isLongest = true;
      if (G.gameMode === GameMode.Mega || G.gameMode === GameMode.UsaClassic || G.gameMode === GameMode.Demo) {
        G.playersData[i].score += 10;
        console.log('Player ' + i + ' got the longest path! + 10 points.');
      }
    }
  }
}

function calculateGlobeTrotter(G: GameState, ctx: Ctx) {
  const playersData = G.playersData;
  let highestGlobeTrotter = 0;
  for (let i = 0; i < playersData.length; i++) {
    const numberOfCompletedMission = countCompletedMissions(playersData[i]);
    if (highestGlobeTrotter < numberOfCompletedMission) {
      highestGlobeTrotter = numberOfCompletedMission;
    }
  }
  for (let i = 0; i < playersData.length; i++) {
    const numberOfCompletedMission = countCompletedMissions(playersData[i]);
    if (highestGlobeTrotter <= numberOfCompletedMission) {
      G.playersData[i].isGlobeTrotter = true;
      if (G.gameMode === GameMode.Mega || G.gameMode === GameMode.ExpansionClassic) {
        G.playersData[i].score += 15;
        console.log('Player ' + i + ' got the GlobeTrotter! + 15 points.');
      }
    }
  }
}

function countCompletedMissions(playerData) {
  return playerData.missionCards.reduce((count, mission) => {
    if (mission.status === true) {
      count++;
    }
    return count;
  }, 0);
}

function calculateWinner(G: GameState, ctx: Ctx) {
  const highstScore = Math.max(...G.playersData.map((playerdata) => playerdata.score));
  const completedMissionCardsNumberByPlayerID: Record<string, number> = {};
  const longestRoadByPlayerID: Record<string, number> = {};
  let winnerID = -1;
  for (let i = 0; i < G.playersData.length; i++) {
    if (G.playersData[i].score === highstScore) {
      winnerID = i;
      // set up the redord for mission card completion calculation
      completedMissionCardsNumberByPlayerID[i] = G.playersData[i].missionCards.filter(
        (missionCard) => missionCard.status === true
      ).length;
    }
  }
  //in case of a tie, check how completed more mission cards
  if (Object.values(completedMissionCardsNumberByPlayerID).length > 1) {
    const highstNumberOfCompeletedMissionCards = Math.max(...Object.values(completedMissionCardsNumberByPlayerID));
    for (const key in completedMissionCardsNumberByPlayerID) {
      if (completedMissionCardsNumberByPlayerID[key] === highstNumberOfCompeletedMissionCards) {
        winnerID = parseInt(key);
        // set up the redord for longest road calculation
        longestRoadByPlayerID[key] = G.playersData[parseInt(key)].longestRoad.length;
      }
    }
  }
  //in case of a tie on mission completion, check which player has a longer connected road
  if (Object.values(longestRoadByPlayerID).length > 1) {
    let longestRoad = 0;
    for (const key in longestRoadByPlayerID) {
      if (longestRoadByPlayerID[key] >= longestRoad) {
        longestRoad = longestRoadByPlayerID[key];
        winnerID = parseInt(key);
      }
    }
  }
  G.boardData.winnerID = winnerID;
  console.log('And the winner is ' + G.players[winnerID].name);
}

function areCitiesConnected(G: GameState, playerID: number, cityA: City, cityB: City) {
  console.log('Checking if two cities are connected');
  // Create a visited set to keep track of visited nodes
  const visited = new Set();

  // Create a queue for BFS traversal
  const queue = [];

  // Enqueue the starting node
  queue.push(cityA);

  // Perform BFS
  while (queue.length > 0) {
    const currentCity = queue.shift();

    // Mark the current city as visited
    visited.add(currentCity);

    // Check if the current city is the target city
    if (currentCity === cityB) {
      return true; // Nodes are connected
    }

    // Find adjacent cities connected to the current city
    const adjacentCities = G.boardData.routes.filter(
      (route) => route.ownerID === playerID && (route.cityA === currentCity || route.cityB === currentCity)
    );

    // Enqueue the adjacent cities if they are not visited
    for (const route of adjacentCities) {
      const nextCity = route.cityA === currentCity ? route.cityB : route.cityA;
      if (!visited.has(nextCity)) {
        queue.push(nextCity);
      }
    }
  }
  return false; // Nodes are not connected
}

function checkIfThereAreMoreThanThreeMultiColorTrainCardsOnTheTable(G: GameState): boolean {
  console.log('Checking if there are more than 3 multicolor train cards on the table');
  let numberOfMultiColorTrainCardsOnTheTable = 0;
  G.boardData.trainCardsTable.forEach((trainCard) => {
    if (trainCard !== null && trainCard.Color === TrainColor.Multicolored) {
      numberOfMultiColorTrainCardsOnTheTable++;
    }
  });
  if (numberOfMultiColorTrainCardsOnTheTable > 2) {
    return true;
  }
  return false;
}

function checkIfthereAreEnoughRegularTrainCardsToRefreshTable(G: GameState): boolean {
  console.log('Checking if there are enough regular cards to refresh table');
  let numberOfRegularTrainCards = 0;
  G.boardData.trainCardsDeck.forEach((trainCard) => {
    if (trainCard.Color !== TrainColor.Multicolored) {
      numberOfRegularTrainCards++;
    }
  });
  G.boardData.usedTrainCards.forEach((trainCard) => {
    if (trainCard.Color !== TrainColor.Multicolored) {
      numberOfRegularTrainCards++;
    }
  });
  G.boardData.trainCardsTable.forEach((trainCard) => {
    if (trainCard !== null && trainCard.Color !== TrainColor.Multicolored) {
      numberOfRegularTrainCards++;
    }
  });
  if (numberOfRegularTrainCards > 2) {
    return true;
  }
  return false;
}

function refreshTrainCardsOnTable(G: GameState, ctx: Ctx) {
  console.log('Refreshing train cards on Table');
  G.boardData.usedTrainCards = G.boardData.usedTrainCards.concat(
    G.boardData.trainCardsTable.filter((card) => card !== null)
  );
  if (G.boardData.trainCardsDeck.length < 5 && G.boardData.usedTrainCards.length > 0) {
    reshuffleTrainDeck(G, ctx);
  }
  if (G.boardData.trainCardsDeck.length >= 5) {
    const numberOfCardsToOpen = Math.min(5, G.boardData.trainCardsDeck.length);
    G.boardData.trainCardsTable = G.boardData.trainCardsDeck.splice(0, numberOfCardsToOpen);
  }
}

function rememberDiscardedCards(G: GameState) {
  console.log('remebering discarded train cards from the Table due to 3 jokers refresh');
  G.boardData.cardsDiscardedByJokersLastTurn.push(G.boardData.trainCardsTable);
  console.log(G.boardData.cardsDiscardedByJokersLastTurn[0].map((card) => card.Color));
}

function findLongestRoad(routes: Array<Route>): { longestPath: number; longestPathEdges: Array<Route> } {
  // Create an adjacency list to represent the graph
  const graph = {};
  for (const route of routes) {
    graph[route.cityA] = graph[route.cityA] || [];
    graph[route.cityB] = graph[route.cityB] || [];
    graph[route.cityA].push({ vertex: route.cityB, length: route.numberOfTrains, used: false });
    graph[route.cityB].push({ vertex: route.cityA, length: route.numberOfTrains, used: false });
  }

  let longestPath = 0;
  let longestPathEdges: Array<Route> = [];

  function dfs(currentVertex, currentPathLength, currentPathEdges) {
    if (currentPathLength > longestPath) {
      longestPath = currentPathLength;
      longestPathEdges = currentPathEdges.slice();
    }

    for (const { vertex, length, used } of graph[currentVertex]) {
      if (!used) {
        // const edge = `${currentVertex}-${vertex}`;
        graph[currentVertex].find((edgeInfo) => edgeInfo.vertex === vertex).used = true;
        graph[vertex].find((edgeInfo) => edgeInfo.vertex === currentVertex).used = true;
        currentPathEdges.push({ cityA: currentVertex, cityB: vertex, length });
        dfs(vertex, currentPathLength + length, currentPathEdges);
        graph[currentVertex].find((edgeInfo) => edgeInfo.vertex === vertex).used = false;
        graph[vertex].find((edgeInfo) => edgeInfo.vertex === currentVertex).used = false;
        currentPathEdges.pop();
      }
    }
  }

  for (const vertex in graph) {
    dfs(vertex, 0, []);
  }

  longestPathEdges.forEach((route) => {
    const requestedRoute = routes.find(
      (playerRoute) =>
        (route.cityA === playerRoute.cityA && route.cityB === playerRoute.cityB) ||
        (route.cityA === playerRoute.cityB && route.cityB === playerRoute.cityA)
    );
    if (requestedRoute !== undefined) {
      route.routeID = requestedRoute.routeID;
    }
  });

  return { longestPath, longestPathEdges };
}

function excludeParallelRoutesIfNeeded(G: GameState, ctx: Ctx, establishedRouteID: number) {
  const establishedRoute = G.boardData.routes.find((route) => route.routeID === establishedRouteID);
  if (G.players.length < 4) {
    const parallelRoute = G.boardData.routes.find(
      (route) =>
        ((route.cityA === establishedRoute.cityA && route.cityB === establishedRoute.cityB) ||
          (route.cityA === establishedRoute.cityB && route.cityB === establishedRoute.cityA)) &&
        route.ownerID === undefined
    );

    if (parallelRoute !== undefined) {
      parallelRoute.ownerID = -1;
    }
  }
}

function playerHandsColors(playerData: PlayerData): Array<TrainColor> {
  return playerData.trainCards.map((card) => card.Color);
}

function hasOnlyMulticoloredCards(playerData: PlayerData): boolean {
  return playerData.trainCards.every((card) => card.Color === TrainColor.Multicolored);
}

function getCardAppearnce(trainCards: Array<TrainCard>): Record<string, number> {
  const cardDictionary: Record<string, number> = {};
  for (const card of trainCards) {
    if (cardDictionary[card.Color] === undefined) {
      cardDictionary[card.Color] = 1;
    } else {
      cardDictionary[card.Color] += 1;
    }
  }
  return cardDictionary;
}

function getAvailableRoutesToBuildByCardColor(G: GameState, ctx: Ctx, playerData: PlayerData) {
  const cardAppearnce: Record<string, number> = getCardAppearnce(playerData.trainCards);
  const updatedAvailableRoutesToBuildByCardColor: Record<string, Array<Route>> = {};
  const playerOnlyHasMulticoloredCards = hasOnlyMulticoloredCards(playerData);
  const playerRoutes: Array<Route> = G.boardData.routes.filter(
    (route) => route.ownerID === parseInt(ctx.currentPlayer)
  );
  let availableRoutes: Array<Route> = G.boardData.routes.filter((route) => route.ownerID === undefined);

  // Filter availableRoutes to only include routes that are not in playerRoutes
  availableRoutes = availableRoutes.filter((availableRoute) => {
    return !playerRoutes.some((playerRoute) => {
      return (
        (availableRoute.cityA === playerRoute.cityA && availableRoute.cityB === playerRoute.cityB) ||
        (availableRoute.cityA === playerRoute.cityB && availableRoute.cityB === playerRoute.cityA)
      );
    });
  });

  for (const color in cardAppearnce) {
    updatedAvailableRoutesToBuildByCardColor[color] = [];
    if (color !== TrainColor.Multicolored) {
      availableRoutes.forEach((route) => {
        if (
          (route.routeColor === color || route.routeColor === 'Any') &&
          route.numberOfTrains <= playerData.trainCars &&
          route.numberOfTrains <= (cardAppearnce[color] ?? 0) + (cardAppearnce[TrainColor.Multicolored] ?? 0)
        ) {
          updatedAvailableRoutesToBuildByCardColor[color].push(route);
        }
      });
    } else {
      if (playerOnlyHasMulticoloredCards) {
        availableRoutes.forEach((route) => {
          if (
            route.numberOfTrains <= (cardAppearnce[TrainColor.Multicolored] ?? 0) &&
            route.numberOfTrains <= playerData.trainCars
          ) {
            updatedAvailableRoutesToBuildByCardColor[color].push(route);
          }
        });
      } else {
        const playerColors = playerHandsColors(playerData);
        availableRoutes.forEach((route) => {
          if (
            !playerColors.includes(route.routeColor) &&
            route.numberOfTrains <= (cardAppearnce[TrainColor.Multicolored] ?? 0) &&
            route.numberOfTrains <= playerData.trainCars
          ) {
            updatedAvailableRoutesToBuildByCardColor[color].push(route);
          }
        });
      }
    }
  }
  return updatedAvailableRoutesToBuildByCardColor;
}
